@import '../../variables.scss';

.navbar {
	width: 100%;
	height: 50px;
	position: sticky;
	top: 0;
	background-color: $secondary-color;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 70px;
	z-index: 1000;

	@media screen and (max-width: 1150px) {
		gap: 70px;
	}
	@media screen and (max-width: 750px) {
		gap: 50px;
	}
	@media screen and (max-width: 500px) {
		gap: 30px;
	}
	@media screen and (max-width: 370px) {
		gap: 15px;
		font-size: 0.8rem;
	}

	a {
		color: $primary-color;
	}
	a:hover {
		color: $primary-hover;
	}
	.active {
		color: $accent-color;
	}
	.active:hover {
		color: $accent-hover;
	}
}
