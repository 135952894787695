@import '../../variables.scss';

.footer {
	width: 100%;
	height: 250px;

	display: flex;

	.footer-light {
		height: 100%;
		width: 50%;
		position: relative;
		background-color: $primary-color;
		padding: 30px 0 0 50px;
		display: flex;
		flex-direction: column;

		img {
			height: 100px;
			width: 150px;
			margin-bottom: 20px;
		}

		.business {
			font-size: 1.1rem;
			font-weight: 700;
		}
		p {
			font-size: 0.9rem;
			font-weight: 500;
		}

		svg {
			position: absolute;
			top: 0;
			right: 0;
		}

		a {
			color: $accent-color;
			&:hover {
				color: $accent-hover;
			}
		}
	}
	.footer-dark {
		height: 100%;
		width: 50%;
		background-color: $secondary-color;
		padding: 40px 80px 0 50px;
		display: flex;
		justify-content: space-between;

		.display {
			width: fit-content;
			height: fit-content;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 5px;

			.line {
				width: 120px;
				height: 1.5px;
				border-radius: 5px;
				background-color: $accent-color;
				margin-bottom: 5px;
			}

			.title {
				font-size: 1.4rem;
			}

			p {
				color: $primary-color;
				text-align: center;
			}

			a:hover p {
				color: $primary-hover;
			}

			.copy {
				transition: all 0.1s ease-in-out;
			}

			.copy:hover {
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 1150px) {
		height: 500px;
		flex-direction: column;
		.footer-light {
			width: 100%;
			svg {
				display: none;
			}
		}
		.footer-dark {
			width: 100%;
			padding-left: 200px;
			padding-right: 200px;
		}
	}

	@media screen and (max-width: 870px) {
		.footer-dark {
			padding: 40px 60px 0 50px;
		}
	}

	@media screen and (max-width: 570px) {
		.footer-light {
			padding-bottom: 50px;
		}
		.footer-dark {
			padding-bottom: 40px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 30px;
		}
	}

	@media screen and (max-width: 370px) {
		.footer-light {
			padding-left: 20px;
			img {
				width: 100px;
				height: 65px;
			}

			.business {
				font-size: 0.9rem;
			}

			p {
				font-size: 0.8rem;
			}
		}
	}
}
