@import '../../variables.scss';

.page-about {
	background-color: $primary-color;
	padding: 50px 20px;

	.presentation {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 50px;
		margin-bottom: 100px;

		.slide {
			width: 100%;
			padding: 0 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 100px;
			flex-wrap: wrap;

			img {
				width: 700px;
				height: 400px;
				object-fit: cover;
				border-radius: 10px;
			}

			p {
				width: 450px;
				font-size: 1.2rem;
				text-align: center;
			}
		}

		.slide:last-of-type {
			flex-direction: row-reverse;
		}

		@media screen and (max-width: 1450px) {
			.slide {
				gap: 50px;
			}
		}
		@media screen and (max-width: 1000px) {
			.slide {
				img {
					width: 500px;
					height: 300px;
				}
			}
		}
		@media screen and (max-width: 650px) {
			.slide {
				img {
					width: 400px;
					height: 250px;
				}
				p {
					font-size: 1.2rem;
				}
			}
		}
		@media screen and (max-width: 500px) {
			.slide {
				img {
					width: 350px;
					height: 220px;
				}
			}
		}
		@media screen and (max-width: 400px) {
			.slide {
				img {
					width: 300px;
					height: 200px;
				}
			}
		}

		@media screen and (max-width: 370px) {
			.slide {
				img {
					width: 220px;
					height: 150px;
				}
				p {
					font-size: 0.9rem;
				}
			}
		}
	}

	.personal {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 50px;
		margin-bottom: 100px;

		.header {
			font-size: 2rem;
			text-align: center;
			color: $accent-color;
		}

		.body {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			justify-items: center;
			row-gap: 50px;

			@media only screen and (max-width: 1300px) {
				grid-template-columns: repeat(2, 1fr);
			}
			@media only screen and (max-width: 950px) {
				grid-template-columns: repeat(1, 1fr);
			}
			@media only screen and (max-width: 530px) {
				padding: 50px 5px;
			}
		}
		@media only screen and (max-width: 530px) {
			.header {
				font-size: 1.8rem;
			}
		}
	}

	.testimonials {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;

		p {
			text-align: center;
			font-size: 2rem;
			margin-bottom: 10px;
			color: $accent-color;
		}

		img {
			border-radius: 10px;
		}

		@media screen and (max-width: 750px) {
			img {
				width: 600px;
			}
		}
		@media screen and (max-width: 700px) {
			p {
				font-size: 1.8rem;
			}
			img {
				width: 500px;
			}
		}
		@media screen and (max-width: 550px) {
			img {
				width: 400px;
			}
		}
		@media screen and (max-width: 450px) {
			img {
				width: 350px;
			}
		}
		@media screen and (max-width: 400px) {
			img {
				width: 300px;
			}
		}
		@media screen and (max-width: 370px) {
			img {
				width: 280px;
			}
		}
		@media screen and (max-width: 320px) {
			img {
				width: 220px;
			}
		}
	}
}
