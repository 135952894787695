@import '../../variables.scss';

.category-card {
	width: 330px;
	height: fit-content;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	.header {
		width: 100%;
		height: 210px;
		border-radius: 20px 20px 0 0;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 150px;
		}
	}

	.body {
		height: 220px;
		background-color: $secondary-color;
		color: white;
		border-radius: 0 0 20px 20px;
		padding: 30px;

		.title {
			font-size: 1.3rem;
			margin-bottom: 15px;
		}

		.vehicles {
			margin-left: 10px;
			font-size: 1.1rem;

			p {
				margin-bottom: 10px;
			}
		}
	}

	.button {
		width: 100px;
		height: 40px;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $accent-color;
		color: white;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 50%);
	}

	.button:hover {
		cursor: pointer;
		background-color: $accent-hover;
	}

	@media only screen and (max-width: 530px) {
		width: 300px;
		.header {
			height: 180px;
		}
	}
	@media screen and (max-width: 370px) {
		width: 220px;
		.header {
			height: 140px;
			img {
				width: 100px;
			}
		}
		.body {
			height: 190px;
			.title {
				font-size: 1rem;
			}
			.vehicles {
				font-size: 0.9rem;
			}
		}
	}
}
