@import '../../variables.scss';

.page-home {
	background-color: $primary-color;

	.presentation {
		width: 100%;
		height: 800px;
		position: relative;
		margin-bottom: 100px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		p {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: white;
			font-size: 3.5rem;
			text-shadow: 0 0 4px rgba(0, 0, 0, 0.575);
			white-space: nowrap;
		}

		@media screen and( max-width: 1500px) {
			height: 700px;
		}
		@media screen and( max-width: 1050px) {
			height: 600px;
		}
		@media screen and( max-width: 950px) {
			height: 500px;
			p {
				font-size: 2.5rem;
			}
		}
		@media screen and( max-width: 750px) {
			height: 300px;
			p {
				font-size: 2rem;
			}
		}
		@media screen and( max-width: 550px) {
			height: 200px;
		}
		@media screen and (max-width: 370px) {
			margin-bottom: 50px;
		}
	}

	//carousel
	.carousel {
		width: 100%;
		height: 700px;
		position: relative;

		.swiper {
			width: 100%;
			height: 100%;

			.swiper-wrapper {
				width: 100% !important;
				height: 100% !important;
			}
		}

		.carousel-slide {
			width: 100% !important;
			height: 100% !important;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.swiper-button {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 50px;
			height: 65%;
			color: white !important;
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				cursor: pointer;
			}

			.button-icon {
				width: 40px;
				height: 40px;
			}
		}
		.next-button {
			right: 0;
		}
		.prev-button {
			left: 0;
		}

		@media screen and( max-width: 1200px) {
			height: 500px;
		}
		@media screen and( max-width: 600px) {
			height: 300px;
		}
		@media screen and( max-width: 500px) {
			height: 200px;
		}
	}

	.categorii {
		margin: 0 20px 50px 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		p.title {
			color: $accent-color;
			font-size: 2rem;
			&:hover {
				color: $accent-hover;
			}
		}
		.body {
			width: 90%;
			margin: 0 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			p.description {
				width: 600px;
				font-size: 1.3rem;
			}

			.icons {
				align-self: center;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				gap: 100px;
				margin-right: 50px;
				img {
					width: 200px;
				}
			}
		}

		@media screen and( max-width: 1650px) {
			.body {
				p.description {
					width: 500px;
				}

				.icons {
					gap: 50px;
					margin: 0;
				}
			}
		}
		@media screen and( max-width: 1350px) {
			.body {
				flex-direction: column;
				align-items: center;
			}
			p.description {
				text-align: center;
			}
		}
		@media screen and( max-width: 600px) {
			.body p.description {
				width: 400px;
			}
		}
		@media screen and( max-width: 550px) {
			p.title {
				margin: 0;
			}

			.body {
				margin: 0 10px;

				p.description {
					width: 300px;
				}

				.icons {
					gap: 20px;
				}
			}
		}
		@media screen and (max-width: 370px) {
			p.title {
				font-size: 1.8rem;
			}
			p.description {
				width: 200px !important;
				font-size: 1.1rem !important;
			}
			.icons {
				gap: 10px !important;
				img {
					width: 150px !important;
				}
			}
		}
	}

	.testimonials {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		margin: 0 20px 100px 20px;

		p {
			color: $accent-color;
			font-size: 2rem;
			margin-bottom: 50px;
		}

		img {
			border-radius: 10px;
		}
		@media screen and (max-width: 750px) {
			img {
				width: 600px;
			}
		}
		@media screen and (max-width: 700px) {
			p {
				font-size: 1.8rem;
			}
			img {
				width: 500px;
			}
		}
		@media screen and (max-width: 550px) {
			img {
				width: 400px;
			}
		}
		@media screen and (max-width: 450px) {
			p {
				font-size: 1.5rem;
				margin-bottom: 10px;
			}
			img {
				width: 350px;
			}
		}
		@media screen and (max-width: 400px) {
			img {
				width: 300px;
			}
		}
		@media screen and (max-width: 370px) {
			p {
				font-size: 1.1rem;
			}
			img {
				width: 220px;
			}
			margin-bottom: 50px;
		}
	}
}
