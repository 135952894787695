@import '../../variables.scss';

.logo-header {
	width: 100%;
	height: fit-content;
	padding: 10px;
	background-color: $primary-color;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;

	img {
		width: 130px;
	}
	@media screen and (max-width: 370px) {
		img {
			width: 110px;
		}
	}
}
