@import '../../variables.scss';

.page-category {
	background-color: $primary-color;
	padding-bottom: 70px;
	display: flex;
	flex-direction: column;
	gap: 70px;

	.header {
		width: 100%;
		height: 600px;
		display: flex;

		.icon-title {
			height: 100%;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			img {
				width: 300px;
			}
			p {
				font-size: 2rem;
			}
		}

		//carousel
		.carousel {
			width: 70%;
			position: relative;
			height: 100%;

			.swiper {
				width: 100%;
				height: 100%;

				.swiper-wrapper {
					width: 100% !important;
					height: 100% !important;
				}
			}

			.carousel-slide {
				width: 100% !important;
				height: 100% !important;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.swiper-button {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 50px;
				height: 65%;
				color: white !important;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					cursor: pointer;
				}

				.button-icon {
					width: 40px;
					height: 40px;
				}
			}
			.next-button {
				right: 0;
			}
			.prev-button {
				left: 0;
			}
		}
	}

	.body {
		width: 100%;

		.instructori {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 50px;
			margin-bottom: 50px;

			p {
				font-size: 2rem;
			}

			.cards {
				padding: 0 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 50px;
				flex-wrap: wrap;
			}
			@media screen and (max-width: 370px) {
				.cards {
					padding: 0 15px;
				}
			}
		}

		.documente {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 20px;

			.title {
				font-size: 1.8rem;
			}

			img {
				width: 100px;
			}

			.list {
				display: flex;
				flex-direction: column;
				align-items: center;

				p {
					font-size: 1.3rem;
				}
			}
		}
	}

	@media screen and (max-width: 1250px) {
		.header {
			height: 400px;

			.icon-title {
				img {
					width: 200px;
				}
				p {
					font-size: 1.5rem;
				}
			}
		}
	}
	@media screen and (max-width: 1020px) {
		.header {
			height: 300px;
		}
	}
	@media screen and (max-width: 850px) {
		.header {
			flex-direction: column;
			height: fit-content;
			gap: 20px;
			align-items: center;
			.carousel {
				width: 100%;
				height: 500px;
				.swiper-wrapper {
					align-items: center;
				}
			}
		}
	}
	@media screen and (max-width: 750px) {
		.header .carousel {
			height: 400px;
		}
	}
	@media screen and (max-width: 700px) {
		.header .carousel {
			height: 300px;
		}
	}
	@media screen and (max-width: 520px) {
		.header .carousel {
			height: 220px;
		}
	}

	@media screen and (max-width: 370px) {
		.header {
			.icon-title {
				img {
					width: 180px;
				}
			}

			.carousel {
				height: 200px;
			}
		}

		.body .documente {
			.title {
				font-size: 1.3rem;
			}
			.list p {
				font-size: 1.1rem;
			}
		}
	}
}
