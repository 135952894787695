@import "../../variables.scss";

.instructor-card {
	width: 300px;
	height: fit-content;

	.header {
		width: 100%;
		height: 180px !important;
		background-color: $primary-color;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;

		img {
			width: 100%;
			height: 180px;
			object-fit: cover;
			border-radius: 20px;
			image-rendering: optimizeQuality;
			overflow-clip-margin: unset;
		}
	}

	.body {
		background-color: $secondary-color;
		color: $primary-color;
		border-radius: 20px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p:first-of-type {
			font-size: 1.3rem !important;
		}

		p {
			font-size: 1rem !important;
			text-align: center;
		}
	}

	@media screen and (max-width: 380px) {
		width: 250px;
	}
}
