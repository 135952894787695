@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
}

body {
	font-family: 'Poppins', sans-serif;
}

.App {
	width: 100%;
	display: flex;
	flex-direction: column;
	scroll-behavior: smooth;
}

.page {
	width: 100%;
	flex-grow: 1;
}
