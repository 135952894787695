@import "../../variables.scss";

.instructorcar-card {
	width: 350px;
	height: fit-content;
	padding-bottom: 30px;
	background-color: $secondary-color;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;

	img {
		width: 100%;
		height: 200px;
		border-radius: 10px 10px 0 0;
		margin-bottom: 30px;
		object-fit: cover;
		image-rendering: optimizeQuality;
		overflow-clip-margin: unset;
	}

	p {
		color: $primary-color;
		font-size: 1.1rem;
	}

	@media screen and (max-width: 450px) {
		width: 300px;
		img {
			height: 170px;
		}
	}
	@media screen and (max-width: 370px) {
		width: 210px;
		img {
			height: 120px;
		}
		p {
			font-size: 0.9rem;
		}
	}
}
