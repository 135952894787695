@import '../../variables.scss';

.page-contact {
	background-color: $secondary-color;
	color: $primary-color;

	.map-contact {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 100px;
		padding: 60px 100px;

		img {
			width: 700px;
			border-radius: 15px;
		}
		img:hover {
			cursor: pointer;
		}

		.contact-details {
			font-size: 1.2rem;
			display: flex;
			flex-direction: column;
			gap: 10px;

			.orar {
				display: flex;
			}

			span {
				cursor: pointer;
				transition: all 0.1s ease-in-out;
			}
		}

		@media screen and (max-width: 1420px) {
			flex-direction: column;
			gap: 50px;
		}
		@media screen and (max-width: 850px) {
			img {
				width: 500px;
			}
		}
		@media screen and (max-width: 620px) {
			padding: 60px 20px;
			img {
				width: 400px;
			}
			.contact-details {
				font-size: 1rem;
			}
		}
		@media screen and (max-width: 430px) {
			img {
				width: 370px;
			}
		}
		@media screen and (max-width: 410px) {
			img {
				width: 300px;
			}
			.contact-details {
				font-size: 0.9rem;
			}
		}
		@media screen and (max-width: 370px) {
			img {
				width: 220px;
			}
			.contact-details {
				font-size: 0.7rem;
				span.address {
					margin-left: 15px !important;
				}
				span.email {
					margin-left: 25px !important;
				}
				span.tel {
					margin-left: 15px !important;
				}
				.days {
					margin-left: 30px !important;
				}
				.hours {
					margin-left: 35px !important;
				}
			}
		}
	}

	.social-media {
		width: 100%;
		height: fit-content;
		margin-top: 50px;
		margin-bottom: 200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 5px;
		.title {
			font-size: 1.4rem;
		}
		.line {
			width: 120px;
			height: 1.5px;
			border-radius: 5px;
			background-color: $accent-color;
			margin-bottom: 10px;
		}

		.platforms {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;
			margin-bottom: 20px;
			cursor: pointer;
			color: $primary-color;
			img {
				width: 50px;
			}
		}
		.insta {
			margin-left: 20px;
			img {
				width: 45px;
			}
		}

		@media screen and (max-width: 370px) {
			.title {
				font-size: 1.2rem;
			}
			.platforms {
				font-size: 0.9rem;
				img {
					width: 30px;
				}
			}
		}
	}
}
