@import '../../variables.scss';

.page-oferta {
	background-color: $primary-color;
	padding: 50px 100px 100px 100px;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-items: center;
	row-gap: 50px;

	@media only screen and (max-width: 950px) {
		grid-template-columns: repeat(1, 1fr);
	}
	@media only screen and (max-width: 530px) {
		padding: 50px 5px;
	}
}
